<template>
  <!-- 表单管理 -->
  <div class="formment-box">
    <div class="box">
      <div class="top-nav">
        <div class="title">表单记录</div>
      </div>
      <div class="search-box">
        <el-select v-model="value" placeholder="请选择">
          <el-option
            v-for="item in selecttimeoptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div class="search-input" style="margin-left: 10px">
          <el-date-picker
            v-model="datetime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
          <!-- <el-input
            v-model="search"
            placeholder="请输入表单名称搜索"
          ></el-input> -->
          <!-- <i class="el-icon-search"></i> -->
        </div>
        <el-button @click="searchClick">搜索</el-button>
      </div>
      <div>
        <infotable
          :tableHeadConfig="formMenttitle"
          :selection="false"
          align="left"
          :tableLoadData="tablelist"
        >
          <template v-slot:Name="slotData">
            <div class="username-box">
              <img :src="slotData.data.row.pic" alt="" class="img" />
              <span>{{ slotData.data.row.Name }}</span>
            </div>
          </template>
          <template v-slot:status="slotData">
            <el-tag type="success" v-if="slotData.data.row.status == 2">
              已审批
            </el-tag>
            <el-tag type="danger" v-if="slotData.data.row.status == 1">
              未通过
            </el-tag>
            <el-tag type="info" v-if="slotData.data.row.status == 0">
              待审批
            </el-tag>
          </template>
          <template v-slot:operation="slotData">
            <div class="oprtion-style">
              <div class="oper-modify" @click="setlist(slotData.data.row)">
                查看
              </div>

              <div class="border-box"></div>
              <div class="oper-del" @click="dellist(slotData.data.row)">
                删除
              </div>
            </div>
          </template>
        </infotable>
      </div>
      <div class="bottom-pagination">
        <bottom-pagination
          @pageclcik="pageclcik"
          :pagination="pagination"
        ></bottom-pagination>
      </div>
    </div>
    <el-dialog title="查看表单" :visible.sync="dialogVisible" width="500px">
      <div
        v-for="(item, index) in fromRecordlist"
        :key="index"
        class="form-list-box"
      >
        <div class="form-list">
          <div style="flex: 2">
            <span v-if="item.data">{{ item.data.title }}</span> ：
          </div>
          <div style="flex: 3; padding: 10px">
            <span v-if="item.id != 'table' && item.id != 'uploadImg'">{{
              item.value
            }}</span>

            <span v-if="item.id == 'uploadImg'">
              <img
                v-for="(item2, index2) in item.value"
                :key="index2"
                :src="item2"
                style="width: 70px; height: 70px"
              />
            </span>
            <span v-if="item.id == 'table'" style="height: 46px">
              <span v-for="(item2, index2) in item.value" :key="index2"
                >{{ item2.label }}*{{ item2.number }}{{ item2.unit }}</span
              >
            </span>
          </div>
        </div>
      </div>
      <div style="display: flex; justify-content: center; margin-top: 20px">
        <el-button
          style="
            background: #f5f6fa;
            border-color: #f5f6fa;
            width: 103px;
            color: #999;
          "
          @click="btn(2)"
          >打回</el-button
        >
        <el-button
          style="
            background: #4458fe;
            color: #fff;
            border: 0;
            width: 103px;
            margin-left: 20px;
          "
          @click="btn(1)"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { sessionGetKey } from "@/utils/sessionStorage.js";
import pagination from "@/components/pagination/pagination.vue";
import infotable from "@/components/infoTable/infoTable.vue";

export default {
  data() {
    return {
      datetime: "",
      value: 0,
      selecttimeoptions: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "今天",
        },
        {
          value: 2,
          label: "昨日",
        },
        {
          value: 3,
          label: "本周",
        },
        {
          value: 4,
          label: "上周",
        },
        {
          value: 5,
          label: "本月",
        },
        {
          value: 6,
          label: "上月",
        },
      ],
      time: {},
      pagination: {},
      page: 1,
      fromRecordlist: [
        {
          name: "表单名称",
          val: "2323233",
        },
        {
          name: "公司名称",
          val: "2323233",
        },
      ],
      size: "",
      id: "",
      dialogVisible: false,
      tablelist: [],
      formMenttitle: [
        {
          label: "ID",
          field: "order_id",
          sort: true,
        },
        {
          label: "商品",
          columnType: "slot",
          slotName: "Name",
          field: "Name",
        },
        {
          label: "表单名称",

          field: "good_name",
        },
        {
          label: "提交时间",
          field: "created_at",
        },
        {
          label: "是否审批",
          slotName: "status",
          columnType: "slot",
        },
        {
          label: "操作",
          columnType: "slot",
          slotName: "operation",
        },
      ],
      search: "",
      platform_id: "",
    };
  },
  components: {
    infotable,
    "bottom-pagination": pagination,
  },
  mounted() {
    this.getlist();
    const platform_id = sessionGetKey("platformId");
    this.platform_id = platform_id;
  },
  methods: {
    btn(val) {
      let that = this;
      that
        .$request({
          url: that.$api.formApi.setorder + `?platform_id=${this.platform_id}`,
          method: "post",
          data: {
            id: this.id,
            status: val,
          },
        })
        .then((res) => {
          this.dialogVisible = false;
          this.getlist();
        });
    },
    dellist(e) {
      console.log(e);
      let that = this;
      const platform_id = sessionGetKey("platformId");
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          that
            .$request({
              url: that.$api.formApi.delorder + `?platform_id=${platform_id}`,
              method: "post",
              data: {
                id: e.order_id,
              },
            })
            .then((res) => {
              if (res.code == 0) {
                that.getlist();
              } else {
                that.$message.error("删除数据失败");
              }
              console.log(res);
            })
            .catch((err) => {
              that.$message.error("数据失败");
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    setlist(e) {
      this.dialogVisible = true;
      this.id = e.order_id;
      console.log(e, e.good_detail);
      this.fromRecordlist = JSON.parse(e.good_detail);
      // this.fromRecordlist.filter(val=>{
      //   val.data=JSON.parse(val.data)
      // })
      console.log(this.fromRecordlist);
    },
    btnclick(e) {
      console.log(e);
    },
    calcelclick() {},
    searchClick() {
      if (this.datetime.length) {
        this.time.start_time = this.getdateTime(this.datetime[0]);
        this.time.end_time = this.getdateTime(this.datetime[1]);
        console.log(this.time);
      }
      this.getlist();
    },
    pageclcik(e) {
      this.page = e;
      console.log(e, "page");
      this.getlist();
    },
    getlist() {
      let that = this;
      const platform_id = sessionGetKey("platformId");
      this.$request({
        url: this.$api.formApi.searchorder + `?platform_id=${platform_id}`,
        method: "post",
        data: {
          code: that.value,
          start_time: that.time.start_time,
          end_time: that.time.end_time,
          status: null,
          page: that.page,
        },
      })
        .then((res) => {
          if (res.code == 0) {
            that.pagination = {
              currentpage: res.data.current_page,
              total: res.data.total,
              totalpage: res.data.last_page,
              pageSize: res.data.per_page,
            };
            that.tablelist = res.data.data;
            that.tablelist.filter((val) => {
              val.good_detail = JSON.parse(val.good_detail);
            });
            console.log(that.tablelist);
          }
          console.log(res);
        })
        .catch((err) => {
          that.$message.error(err.message);
          console.log(err);
        });
    },
    getdateTime(val = new Date()) {
      let year = new Date(val).getFullYear();
      let month = new Date(val).getMonth() + 1;
      let day = new Date(val).getDate();
      let hour = new Date(val).getHours();
      let Minutes = new Date(val).getMinutes();
      let Seconds = new Date(val).getSeconds();
      return `${year}-${month > 9 ? month : "0" + month}-${
        day > 9 ? day : "0" + day
      } ${hour > 9 ? hour : "0" + hour}:${
        Minutes > 9 ? Minutes : "0" + Minutes
      }:${Seconds > 9 ? Seconds : "0" + Seconds}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.username-box {
  .img {
    width: 70px;
    height: 70px;
    margin-right: 5px;
  }
  display: flex;
  align-items: center;
}
.fontweight9 {
  font-weight: 900;
}
.fontweight4 {
  font-weight: 400;
  margin-left: 3px !important;
}
.formment-box {
  height: 100%;
  padding: 66px;
  min-height: 600px;
  overflow: hidden;
  .border-box {
    width: 1px;
    height: 18px;
    background: #4458fe;
    margin: 0 10px;
  }
  .oprtion-style {
    display: flex;
    align-items: center;
  }
  .oper-modify {
    color: #4458fe;
  }
  .oper-del {
    color: #4458fe;
  }
  .form-list {
    // height: 35px;
    min-height: 40px;
    display: flex;
    align-items: center;

    // line-height: 35px;
    padding: 0 20px;
  }
  .form-list-box:nth-child(odd) {
    background: #f5f6fa;
  }
  /deep/.el-dialog__body {
    padding: 3px 20px 30px;
  }
  .box {
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    width: 100%;
    // height: 100%;
    min-height: 100%;
    background: #fff;
    padding: 33px;
    .top-nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-weight: 600;
        padding-bottom: 10px;
        color: #4458fe;
        font-size: 20px;
        border-bottom: #4458fe 2px solid;
      }
    }

    .search-box {
      margin: 21px 0;
      display: flex;
      align-items: center;
      .search-input {
        margin-right: 20px;
        padding: 0 15px;
        border-radius: 6px;
        width: 400px;
        box-sizing: border-box;
        border: 1px solid #999;
        display: flex;
        align-items: center;
        /deep/.el-input__inner {
          border: 0 !important;
          padding: 0 !important;
        }
        .el-icon-search {
          font-size: 20px;
          color: #999;
        }
      }
      .el-button {
        border: #4458fe 1px solid;
        background-color: #4458fe !important;
        color: #ffffff;
      }
    }
  }
  .bottom-pagination {
    // position: absolute;
    // left: 0;
    // right: 0;
    // bottom: 30px;
    margin-top: 20px;
  }
}
</style>